.skillsDiv {
  height: 100%;
  letter-spacing: 5px;
  text-transform: uppercase;
  margin-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  font-family: "Poppins";
}

#skillsMainHeader {
  margin-left: 0px;
}

.skillHeader {
  opacity: 0.15;
  font-size: 60px;
}

.skillsTitle {
  font-size: large;
}

.skillDiv {
  margin-bottom: 5px;
}

@media screen and (max-width: 500px) {
  .skillsHeader {
    font-size: 5vw;
  }

  .skillsTitle {
    opacity: 0.75;
    font-size: 4vw;
  }

  .skillsDiv {
    grid-template-columns: repeat(1, 1fr);
  }
}
