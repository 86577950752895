.contactDiv {
  letter-spacing: 12px;
  text-transform: uppercase;
  /* margin-top: 40vh; */
}

.connectLogos {
  padding: 30px 20px 0 25px;
  width: 80px;
  height: 80px;
  cursor: pointer;
}

.contactHeader {
  opacity: 0.5;
  font-size: 55px;
}

.children {
  opacity: 1;
  position: relative;
  filter: blur(0);
  font-size: 22px;
  font-weight: lighter;
  font-style: italic;
  font-family: "Roboto";
  text-align: left;
  line-height: 35px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom:10px;
  margin-top:50px;
}

@media screen and (max-width: 700px) {
  .connectLogos {
    padding: 5px 10px 0 10px;
    width: 50px;
    height: 50px;
  }
  .contactHeader {
    font-size: 5vw;
  }

  .children {
    bottom: 5vw;
  }
}
