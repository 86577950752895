.hiddenHeaders {
  opacity: 0;
  filter: blur(5px);
  transform: translateY(-100%);
  transition: all 1.1s;
}

.showHeaders {
  opacity: 1;
  filter: blur(0);
  transform: translateY(0);
}