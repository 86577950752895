@import url("https://fonts.googleapis.com/css2?family=Mr+Dafoe&display=swap");
.headersDiv {
  align-content: center;
  min-height: 100vh;
  display: grid;
  place-items: center;
}

.nameHeader {
  font-family: "Mr Dafoe", cursive;
}

.nameHeader {
  font-size: 100px;
}

.tagHeader {
  letter-spacing: 4px;
  margin-top: -100px;
  font-size: 35px;
}

.projectsHeader {
  font-size: 40px;
  letter-spacing: 10px;
}

@media only screen and (max-width: 700px) {
  .headersDiv {
    display: grid;
    place-items: center;
    min-height: 100vh;
  }

  .nameHeader {
    font-family: "Mr Dafoe", cursive;
  }

  .nameHeader {
    font-size: 70px;
  }

  .tagHeader {
    letter-spacing: 4px;
    margin-top: -80px;
    font-size: 22px;
  }

  .projectsHeader {
    font-size: 40px;
    letter-spacing: 10px;
  }
}

@media only screen and (min-width: 900px) {
  .nameHeader {
    font-size: 130px;
  }
  
}


@media only screen and (max-width: 450px) {
  .nameHeader {
    font-size: 20vw;
  }

  
  .tagHeader {
    letter-spacing: 4px;
    margin-top: .01vh;
    font-size: 6vw;
  }
}


