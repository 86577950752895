.projectTitle {
  margin-left: 25px;
}
.moreProjDetails {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.moreInfolaptop {
  /* width: 600px;
    height: 379px; */
  margin-left: 20px;
  position: relative;
}

.moreInfolaptopScreen {
  text-align: center;
  width: 449px;
  height: 280px;
  position: absolute;
  top: 20px;
  left: 70px;
  /* overflow: hidden; */
  overflow: scroll;
  /* border-radius: 5px; */
}

.moreInfolaptopScreen::-webkit-scrollbar,
.phoneScreen::-webkit-scrollbar {
  display: none;
}

.moreInfolaptopApp {
  width: 100%;
}

.moreInfolaptopImg {
  width: 600px;
}

#moreInfoId {
  font-size: 22px;
  min-height: 100vh;
  line-height: 40px;
}

#InfoProjDesc {
  margin-right: 20px;
}

.gridContainer {
  padding-top: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 80px;
}

.moreInfolaptopMobile {
  display: none;
}

.scrollMobileGif {
  display: none;
}

@media only screen and (max-width: 400px) {
  .scrollMobileGif {
    display: flex;
  }
}

@media only screen and (max-width: 700px) {
  #moreInfoId {
    /* min-height: 10px; */
    font-size: 18px;
    line-height: 23px;
    margin-right: 25px;
  }

  #InfoProjDesc {
    margin-right: 20px;
    width: 100px;
  }

  .moreDetails {
    background-image: linear-gradient(
      to right,
      #232526 0%,
      #414345 51%,
      #232526 100%
    );
    margin: 10px;
    padding: 10px 25px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
    font-size: 12px;
    width: fit-content;
  }

  .moreDetails:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .mobdivMoreInfo {
    margin: 200px;
  }

  .moreInfolaptop {
    display: none;
  }

  .moreInfolaptopMobile {
    display: flex;
    margin-left: -100px;
    margin-right: 20px;
    position: relative;
  }

  .moreInfolaptopImg {
    width: 600px;
  }

  .moreInfolaptopScreen {
    text-align: center;
    width: 188px;
    height: 120px;
    position: absolute;
    top: 8px;
    left: 28px;
    /* overflow: hidden; */
    overflow: scroll;
    /* border-radius: 5px; */
  }

  .moreInfolaptopImg {
    width: 250px;
  }

  .beverages {
    margin-left: 150px;
  }

  #sahakarsecondlaptopMob {
    gap: 30px;
  }

  #githubBtn {
    font-size: 34px;
  }
}

@media only screen and (min-width: 701px) and (max-width: 1020px) {
  .moreInfolaptop {
    /* width: 600px;
    height: 379px; */
    margin-left: 20px;
    position: relative;
    /* padding-right: 1500px; */
  }

  .moreInfolaptopScreen {
    text-align: center;
    width: 186px;
    height: 120px;
    position: absolute;
    top: 7.5px;
    left: 29px;
    /* overflow: hidden; */
    overflow: scroll;
    /* border-radius: 5px; */
  }

  .moreInfolaptopImg {
    width: 250px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1330px) {
  .projectTitle {
    font-size: 26px;
    margin-right: 10px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 450px) {
  #moreInfoId {
    font-size: 4vw;
  }

  .moreDetails {
    font-size: 3vw;
    width: fit-content;
  }

  .moreInfolaptop {
    /* width: 600px;
    height: 379px; */
    margin-left: 20px;
    position: relative;
    /* padding-right: 1500px; */
  }

  .moreInfolaptopScreen {
    text-align: center;
    width: 113px;
    height: 70px;
    position: absolute;
    top: 5px;
    left: 17px;
    /* overflow: hidden; */
    overflow: scroll;
    /* border-radius: 5px; */
  }

  .moreInfolaptopImg {
    width: 150px;
  }
  #sahakarsecondlaptopMob {
    gap: 0px;
  }
}
