.hiddenAboutPara {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(200%);
  transition: all 0s;
  min-height: 80vh;
  font-style: italic;
  font-family: "Roboto";
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");
.showAboutPara {
  opacity: 1;
  position: relative;
  filter: blur(0);
  /* transform: translateX(100%); */
  margin-left: 100px;
  margin-right: 100px;
  font-size: 22px;
  font-weight: lighter;
  font-style: italic;
  font-family: "Roboto";
  bottom: 200px;
  text-align: left;
  line-height: 35px;
}

@media only screen and (max-width: 450px) {
  .showAboutPara {
    opacity: 1;
    position: relative;
    filter: blur(0);
    /* transform: translateX(100%); */
    margin-left: 5vw;
    margin-right: 5vw;
    font-size: 5vw;
    font-family: "Roboto";
    /* bottom: 200px; */
    text-align: left;
    /* line-height: 35px; */
  }
}
