.hiddenAbout {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
  transition: all 1s;
  /* min-height: 80vh; */
}

.showAbout {
  opacity: 0.25;
  filter: blur(0);
  transform: translateX(0);
}

.aboutHeader {
  font-size: 100px;
  text-transform: uppercase;
  letter-spacing: 10px;
  color: black;
  opacity: 0.1;
}

.aboutDiv {
  min-height: 100vh;
  margin-top: 200px;
}

@media only screen and (max-width: 700px) {
  .aboutHeader {
    font-size: 50px;
    margin-left: 65px;
  }
}

@media only screen and (max-width: 450px) {
  .aboutHeader {
    font-size: 15vw;
    margin-left: 50vw;
    opacity: 0.15;
  }
}
