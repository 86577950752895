.projects {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
.projectsTitle {
  font-size: 50px;
  text-transform: uppercase;
  letter-spacing: 10px;
  color: black;
  opacity: 0.1;
  margin-left: 16px;
}

.project {
  display: flex;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  gap: 100px;
  /* margin-left: 50px; */
}

.project:nth-child(2n) {
  flex-direction: row-reverse;
}

.phone {
  /* flex: 1; */
  margin-left: 20px;
  width: 300px;
  height: 600px;
  position: relative;
  /* background-color: aqua; */
}

.phoneScreen {
  text-align: center;
  border-radius: 28px;
  width: 264px;
  height: 567px;
  /* background-color: yellow; */
  position: absolute;
  top: 15px;
  left: 17px;
  overflow: scroll;
  /* 
  Use this for scrollable images
  */
}

.projectDetail {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
}

.projectTitle {
  font-family: "Poppins";

  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 40px;
  opacity: 0.75;
}

.projectDesc {
  font-size: 20px;
  opacity: 0.75;
  line-height: 40px;
  text-align: left;
  margin-left: 30px;
}

.moreDetails {
  background-image: linear-gradient(
    to right,
    #232526 0%,
    #414345 51%,
    #232526 100%
  );
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 5px;
  display: block;
  width: fit-content;
}

.moreDetails:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.laptop {
  /* width: 600px;
  height: 379px; */
  margin-left: 20px;
  position: relative;
}

.laptopScreen {
  text-align: center;
  width: 226px;
  height: 142px;
  position: absolute;
  top: 8px;
  left: 34px;
  /* overflow: hidden; */
  overflow: scroll;
  /* border-radius: 5px; */
}

.laptopScreen::-webkit-scrollbar,
.phoneScreen::-webkit-scrollbar {
  display: none;
}

.laptopApp {
  width: 100%;
  /* border-radius: 2px; */
}

.laptopImg {
  /* width: 100%; */
  width: 300px;
}

@media only screen and (min-width: 1200px) {
  #cipherDeets {
    margin-left: 100px;
  }
}

@media only screen and (max-width: 700px) {
  .projectDetail {
    /* flex: 1; */
    /* margin-right: 20px; */
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
  }

  .projectsTitle {
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: 10px;
    color: black;
    opacity: 0.1;
    margin-left: 180px;
  }

  .projectTitle {
    font-family: "Poppins";
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-size: 22px;
    opacity: 0.75;
  }

  .projectDesc {
    font-size: 18px;
    opacity: 0.75;
    line-height: 25px;
    text-align: left;
    margin-left: 30px;
    /* margin-right: 30px; */
  }
  .project {
    /* align-content: center; */
    display: flex;
    /* align-items: center; */
    height: 100vh;
    overflow: hidden;
    gap: 100px;
  }

  .phone {
    margin-top: auto;
    /* flex: 1; */
    margin-left: 20px;
    width: 300px;
    height: 50vh;
    position: relative;
    /* background-color: aqua; */
  }

  .phoneScreen {
    text-align: center;
    border-radius: 18px;
    width: 120px;
    height: 271px;
    /* background-color: yellow; */
    position: absolute;
    top: 5px;
    left: 5px;
    overflow: scroll;
    /* 
  Use this for scrollable images
  */
  }

  .mobileTemplate {
    width: 132px;
    height: 283px;
  }

  .projects {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  .laptop {
    /* width: 600px;
  height: 379px; */
    margin-left: 20px;
    position: relative;
  }

  .laptopScreen {
    text-align: center;
    width: 146px;
    height: 90px;
    position: absolute;
    top: 5px;
    left: 20px;
    /* overflow: hidden; */
    overflow: scroll;
    /* border-radius: 5px; */
  }

  .laptopImg {
    /* width: 100%; */
    width: 190px;
  }

  .moreDetails {
    background-image: linear-gradient(
      to right,
      #232526 0%,
      #414345 51%,
      #232526 100%
    );
    margin: 10px;
    padding: 10px 25px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 5px;
    display: block;
    width: fit-content;
  }

  .moreDetails:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  #sahakarProjDetail {
    margin-right: 0px;
  }

  #taProjDetail {
    margin-right: 35px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 700px) {
  .project {
    height: 80vh;
  }
}
@media only screen and (min-width: 520px) and (max-width: 700px) {
  .project {
    gap: 160px;
  }
  #cipherGredProj {
    gap: 210px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 700px) {
  
  #stockTraProj {
    gap: 185px;
  }
  #cipherGredProj {
    gap: 265px;
  }


}

@media only screen and (min-width: 700px) and (max-width: 830px) {
  .projectTitle {
    font-size: 26px;
    margin-right: 10px;
  }
}

@media only screen and (min-width: 700px) and (max-width: 750px) {
  .projectsTitle {
    margin-left: 100px;
  }

  .aboutHeader {
    margin-left: 100px;
  }
}

@media only screen and (min-width: 1200px) {
  .project {
    gap: 400px;
  }
}

@media only screen and (max-width: 450px) {
  .project {
    gap: 15vw;
    height: 80vh;
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  .projects {
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  .projectsTitle {
    margin-left: 45vw;
    font-size: 7.8vw;
  }

  .phone {
    margin-top: auto;
    /* flex: 1; */
    margin-left: 20px;
    width: 150px;
    height: 300px;
    position: relative;
    /* background-color: aqua; */
  }

  .phoneScreen {
    text-align: center;
    border-radius: 5px;
    width: 67px;
    height: 135px;
    /* background-color: yellow; */
    position: absolute;
    top: 3px;
    left: 4px;
    overflow: scroll;
    /* 
  Use this for scrollable images
  */
  }

  .mobileTemplate {
    width: 76px;
    height: 142px;
  }

  .projectTitle {
    /* margin-top: 25px; */
    font-size: 4.5vw;
    margin-left: 30px;
    opacity: 1;
  }

  .projectDetail {
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
  }

  .projectDesc {
    font-size: 3vw;
    opacity: 0.75;
    text-align: left;
    margin-left: 30px;
    margin-right: 30px;
  }

  .laptop {
    margin-left: 20px;
    position: relative;
  }

  .laptopScreen {
    text-align: center;
    width: 115px;
    height: 71px;
    position: absolute;
    top: 4px;
    left: 16px;
    /* overflow: hidden; */
    overflow: scroll;
    /* border-radius: 5px; */
  }

  .laptopImg {
    /* width: 100%; */
    width: 150px;
  }

  #TechAnalysisId {
    margin-right: 50x;
    margin-left: -30px;
  }

  .moreDetails {
    background-image: linear-gradient(
      to right,
      #232526 0%,
      #414345 51%,
      #232526 100%
    );
    margin: 5px;
    padding: 10px 5px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 5px;
    display: block;
    /* margin-left: 50px; */
    width: fit-content;
  }

  .moreDetails:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
}
